import type {
  QueryKey,
  UseQueryResult,
  UseInfiniteQueryResult,
} from '@unifyapps/network/react-query';
import type { LocationSensorState } from 'react-use/lib/useLocation';
import type { BlockStateUnionType } from '@unifyapps/defs/types/block';
import type { PageVariableState } from '@unifyapps/defs/types/pageVariable';
import type { PageFunctionState } from '@unifyapps/defs/types/pageFunction';
import type { RefObject } from 'react';
import type { DeviceVariantType, MobileVariant } from '@unifyapps/defs/types/deviceVariant';
import type { UserPermissionsMap } from '../../access-control/types';
import type createObjectSetters from '../../../utils/setters/createObjectSetters';
import type { DeviceVariantActionProps } from './actions/deviceVariantAction';
import type { InterfaceSessionStorageActionProps } from './actions/getInterfaceSessionStorageAction';

type QueryResult = UseQueryResult<Awaited<unknown>, unknown>;

export type DeviceDetailsType = {
  device: DeviceVariantType;
  mobileVariant?: MobileVariant;
};

export type QueryRequestResult = {
  queryKey: QueryKey;
  objects?: unknown[];
} & Pick<QueryResult, 'error' | 'isLoading' | 'isFetching' | 'data'> &
  Partial<
    Pick<UseInfiniteQueryResult, 'isFetchingNextPage' | 'hasNextPage' | 'fetchNextPage' | 'refetch'>
  >;

type DataSourcesResultsSetterType = ReturnType<
  typeof createObjectSetters<GlobalStateStoreType, 'dataSources', QueryRequestResult>
>;

type ManualDataSourcesResultsSetterType = ReturnType<
  typeof createObjectSetters<GlobalStateStoreType, 'manualDataSources', QueryRequestResult>
>;

type BlockSetterType = ReturnType<
  typeof createObjectSetters<GlobalStateStoreType, 'blocks', BlockStateUnionType>
>;

type DependencySetterType = ReturnType<
  typeof createObjectSetters<GlobalStateStoreType, 'dependenciesGraph', EntityDependency[]>
>;

type PageVariableSetterType = ReturnType<
  typeof createObjectSetters<GlobalStateStoreType, 'pageVariables', PageVariableState>
>;

type PageFunctionSetterType = ReturnType<
  typeof createObjectSetters<GlobalStateStoreType, 'pageFunctions', PageFunctionState>
>;

type BlocksRefSetterType = ReturnType<
  typeof createObjectSetters<GlobalStateStoreType, 'blockRefs', RefObject<unknown>>
>;

export type ManuallyTriggeredDataSourcesSetterType = ReturnType<
  typeof createObjectSetters<
    GlobalStateStoreType,
    'manuallyTriggeredDatasources',
    ManuallyTriggeredDatasourceType
  >
>;

type LocationInStore = Pick<
  LocationSensorState,
  'href' | 'hostname' | 'pathname' | 'search' | 'hash'
> & {
  pathParams?: Record<string, string | string[]>;
};

type LocationSetterType = ReturnType<
  typeof createObjectSetters<GlobalStateStoreType, 'location', string>
>;

type UserContextInStore = {
  id?: string;
  locale?: string;
};

type UserContextSetterType = ReturnType<
  typeof createObjectSetters<GlobalStateStoreType, 'userContext', string>
>;

export type DataSourceMetaType = {
  aggregationMetadata?: QueryRequestResult | undefined;
  previewResult?: QueryRequestResult | undefined;
};

export type GlobalStateStoreFlows = {
  importObjectRecords?: {
    onSuccess?: (...args: unknown[]) => unknown;
    onError?: (...args: unknown[]) => unknown;
    onClose?: (...args: unknown[]) => unknown;
    objectId?: string;
    objectInputs?: {
      key: string;
      value: string;
    }[];
  };
};

export type SetPreBuiltFlowParams = {
  type: 'importObjectRecords';
  data: GlobalStateStoreFlows['importObjectRecords'];
};

export type ManuallyTriggeredDatasourceType = {
  callback: ((...args: unknown[]) => unknown) | undefined;
  dataSourceId: string;
  queryKeyPrefix?: string;
};

export type EntityDependency = { property: string };
export type DependenciesGraph = Record<string, EntityDependency[] | undefined>;

export const enum DependencyFlowStatus {
  Staging = 'staging',
  Commit = 'commit',
  Finished = 'finished',
}

export type DependencyFlowInstanceType = Record<
  string,
  { status: DependencyFlowStatus } | undefined
>;

type DependencyFlowManagerType = {
  dataSources: DependencyFlowInstanceType;
};

export type GlobalStateStoreInitialValue = Pick<
  GlobalStateStore,
  | 'deviceDetails'
  | 'pageInputs'
  | 'userContext'
  | 'manuallyTriggeredDatasources'
  | 'location'
  | 'blocks'
  | 'pageVariables'
  | 'permissions'
  | 'interfaceSessionStorage'
  | 'pageFunctions'
  | 'isInitialized'
>;

export type GlobalStateStore = {
  isInitialized?: boolean;
  dataSources: Record<string, QueryRequestResult | undefined>;
  // tentative manual registry, where we keep the inflight manual data sources response, once the response is received, we move it to dataSources, here the key will be the requestId
  manualDataSources: Record<string, QueryRequestResult | undefined>;
  dataSourcesMeta: Record<string, DataSourceMetaType | undefined>;
  blocks: Record<string, BlockStateUnionType>;
  pageVariables: Record<string, PageVariableState | undefined>;
  pageFunctions: Record<string, PageFunctionState | undefined>;
  manuallyTriggeredDatasources: Record<string, ManuallyTriggeredDatasourceType>;
  previewTriggeredDatasources: Record<string, boolean | undefined>;
  pageInputs: Partial<Record<string, unknown>>;
  location: LocationInStore;
  userContext: UserContextInStore;
  blockRefs: Record<string, RefObject<unknown> | undefined>;
  flows: GlobalStateStoreFlows;
  deviceDetails: DeviceDetailsType;
  permissions: UserPermissionsMap;
  interfaceSessionStorage: Record<string, unknown>;
  dependenciesGraph: DependenciesGraph;
  /**
   * intra dependencies are the dependencies that track within the same entity, for example, a data source can have dependencies on other data sources
   */
  intraDependencies: {
    dataSources?: DependenciesGraph;
  };
  /**
   * dependency flow manager is used to manage a id and it's flow status, whether it's in staging, commit or finished
   */
  dependencyFlowManager: DependencyFlowManagerType;
};

export type GlobalStateStoreType = GlobalStateStore & {
  actions: {
    setDataSourceState: DataSourcesResultsSetterType['setSingle'];
    setManualDataSourceState: ManualDataSourcesResultsSetterType['setSingle'];
    removeManualDataSourceState: ManualDataSourcesResultsSetterType['remove'];
    setBlockState: BlockSetterType['setSingle'];
    setBlocksState: BlockSetterType['setMultiple'];
    removeBlocksState: BlockSetterType['removeMultiple'];
    updateBlockState: BlockSetterType['updateSingle'];
    setPageVariableState: PageVariableSetterType['setSingle'];
    setPageVariablesState: PageVariableSetterType['setMultiple'];
    setPageFunctionState: PageFunctionSetterType['setSingle'];
    setUserContext: UserContextSetterType['setMultiple'];
    setLocation: LocationSetterType['setMultiple'];
    removeManuallyTriggeredDataSources: ManuallyTriggeredDataSourcesSetterType['removeMultiple'];
    setAggregationMetadataInDSMeta: (
      dataSourceId: string,
      aggregationMetadata: QueryRequestResult,
    ) => void;
    setPreviewDataInDSMeta: (dataSourceId: string, previewData: QueryRequestResult) => void;
    setBlockRef: BlocksRefSetterType['setSingle'];
    resetBlockRefs: () => void;
    // reset: () => void;
    removeBlockRef: BlocksRefSetterType['remove'];
    setPreBuiltFlow: (params: SetPreBuiltFlowParams) => void;
    removePreBuiltFlow: (key: keyof GlobalStateStoreFlows) => void;
    deviceVariantActions: (args: DeviceVariantActionProps) => void;
    setInterfaceSessionStorage: (args: InterfaceSessionStorageActionProps) => void;
    setDependencies: DependencySetterType['setSingle'];
    datasource: {
      triggerManualDataSource: ManuallyTriggeredDataSourcesSetterType['setSingle'];
      previewDataSource: (id: string, value: boolean) => void;
      removeDataSourceFromStore: (id: string) => void;
      addIntraDependency: (entityId: string, data: EntityDependency[]) => void;
      addIntraDependencies: (entities: Record<string, EntityDependency[]>) => void;
      addDependencyFlow: (entityId: string, status: DependencyFlowStatus) => void;
      addDependencyFlows: (entities: DependencyFlowInstanceType) => void;
    };
    setIsInitialized: () => void;
    reset: () => void;
    updateDependenciesGraph: () => void;
  };
};
