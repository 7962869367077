'use client';

import { createContext, useContext, useMemo } from 'react';
import _isEmpty from 'lodash/isEmpty';

export const BlockRuntimeStateContext = createContext<{
  data: Record<string, unknown>;
  runTimeBlockKey?: string;
}>({
  data: {},
});

export const useBlockRuntimeData = () => {
  const context = useContext(BlockRuntimeStateContext);
  return context.data;
};

export const useRuntimeBlockKey = () => {
  const context = useContext(BlockRuntimeStateContext);
  return context.runTimeBlockKey;
};

export function BlockRuntimeStateContextProvider({
  data,
  runTimeBlockKey,
  children,
}: {
  data: Record<string, unknown>;
  runTimeBlockKey?: string;
  children: React.ReactNode;
}) {
  const value = useMemo(
    () => ({
      data,
      runTimeBlockKey,
    }),
    [data, runTimeBlockKey],
  );

  return (
    <BlockRuntimeStateContext.Provider value={value}>{children}</BlockRuntimeStateContext.Provider>
  );
}
