'use client';
import type { ContextType, PropsWithChildren } from 'react';
import { createContext, useContext, useEffect, useRef } from 'react';
import isEqual from 'react-fast-compare';
// import { parseAsString, useQueryState } from 'nuqs';
import type { BlockId } from '@unifyapps/defs/types/block';
import { useRegistryContext } from '../../components/RegistryProvider';
import { useDeviceDetailsGetter, useGlobalStateStore } from '../GlobalStateStore';
import { useDataSourceExists, useDataSourceRecordStore } from '../DataSourceRecordStore';
import { createInterfaceStore } from './createInterfaceStore';
import type { AdditionalArgs, InterfaceStoreState, InterfaceStoreStateInitialValue } from './types';
import { createInterfaceStoreSelectors } from './selectors';

export const InterfaceStoreContext = createContext<ReturnType<
  typeof createInterfaceStoreSelectors
> | null>(null);

export const useInterfaceStore = () => {
  const _useStore = useContext(InterfaceStoreContext);
  if (!_useStore) {
    throw new Error('Missing StoreProvider');
  }
  return _useStore;
};

export function InterfaceStoreProvider({
  value,
  onChangeActiveBlockId,
  children,
}: PropsWithChildren<{
  value: InterfaceStoreStateInitialValue;
  onChangeActiveBlockId?: (blockId: BlockId) => void;
}>) {
  const storeRef = useRef<ContextType<typeof InterfaceStoreContext>>();
  const { registry } = useRegistryContext();
  const { dataSourceExists } = useDataSourceExists();
  const getDataSourceRecordsStore = useDataSourceRecordStore().getState;

  const {
    setBlockState,
    updateBlockState,
    setPageVariableState,
    setPageFunctionState,
    setBlocksState,
    resetBlockRefs,
    deviceVariantActions: handleDeviceVariantChange,
    setDependencies,
  } = useGlobalStateStore().use.actions();
  const { getDeviceDetails } = useDeviceDetailsGetter();

  if (!storeRef.current) {
    const additionalArgs: AdditionalArgs = {
      dataSourceExists,
      registry,
      setBlockState,
      getDataSourceRecordsStore,
      updateBlockState,
      setBlocksState,
      setPageVariableState,
      setPageFunctionState,
      resetBlockRefs,
      getDeviceDetails,
      handleDeviceVariantChange,
      onChangeActiveBlockId,
      setDependencies,
    };

    const interfaceStoreHook = createInterfaceStore(value, additionalArgs);

    storeRef.current = createInterfaceStoreSelectors(interfaceStoreHook);
  }

  useEffect(() => {
    if (!isEqual(storeRef.current?.getState().interfacePages, value.interfacePages)) {
      storeRef.current?.setState({ interfacePages: value.interfacePages });
    }
  }, [value.interfacePages]);

  return (
    <InterfaceStoreContext.Provider value={storeRef.current}>
      {children}
    </InterfaceStoreContext.Provider>
  );
}
