export const enum UAEntityTypes {
  Component = 'e_component',
  Interface = 'e_interface',
  BaseDomain = 'e_interface_base_domain',
  User = 's_user',
  APIEndpoint = 's_api_endpoint',
  APIEndpointCollection = 's_api_endpoint_collection',
  APIPolicyType = 's_api_policy_type',
  APIAccessProfile = 's_api_access_profile',
  RuntimeEnvironment = 's_remote_runtime_env',
  DataSource = 'e_data_source',
  PlatformAutomation = 'platform_automation',
  ChangeType = 'CHANGE_TYPE',
  IdentityProvider = 's_identity_provider',

  /**
   * This entity gives the name, pluralName of all the modules.
   * Use this entity to get the name of the module. Don't hardcode the module name in code.
   */
  Modules = 'e_platform_module',

  /**
   * This entity gives the application side navigation items.
   */
  Navigation = 'e_platform_navigation',

  NotificationCategory = 'e_notification_category',
  NotificationEventType = 'e_notification_event_type',
  AlertModule = 'e_alert_module',
  AlertModuleGroup = 'e_alert_module_group',
  AlertMetric = 'e_alert_metric',
  AlertDimension = 'e_alert_dimension',
  AlertGroupByMetric = 'e_alert_group_by_metric',
  CodeSnippet = 'e_code_snippet',
}
