import { type StoreApi, type StateCreator, create } from 'zustand';
import type { BlockStateUnionType } from '@unifyapps/defs/types/block';
import type { PageVariableState } from '@unifyapps/defs/types/pageVariable';
import type { RefObject } from 'react';
import { DEFAULT_DEVICE_VARIANT } from '@unifyapps/defs/types/deviceVariant';
import type { DataSourceEntity } from '@unifyapps/defs/types/dataSource';
import type { PageFunctionState } from '@unifyapps/defs/types/pageFunction';
import DataSourceHelper from '../../helper/DataSourceHelper';
import createObjectSetters from '../../../utils/setters/createObjectSetters';
import type { DataSourceRecordStoreState } from '../DataSourceRecordStore';
import { omitKeysFromBlocks } from '../../utils/block';
import getDeviceVariantActions from './actions/deviceVariantAction';
import getInterfaceSessionStorageAction from './actions/getInterfaceSessionStorageAction';
import { buildDependencyGraph } from './utils/buildDependencyGraph';
import type {
  ManuallyTriggeredDatasourceType,
  DataSourceMetaType,
  GlobalStateStore,
  GlobalStateStoreType,
  QueryRequestResult,
  EntityDependency,
  GlobalStateStoreInitialValue,
} from './types';
import DependencyFlowHelper from './helpers/DependencyFlowHelper';
import { getInitialDatasourceDependencyFlow } from './utils/initial';
import getDataSourceActions from './actions/dataSourceActions';

export const createGlobalStateStore = ({
  dataSourceRecords,
  initial,
  getDataSourceRecordsStore,
}: {
  initial?: GlobalStateStoreInitialValue;
  dataSourceRecords?: Record<string, DataSourceEntity | undefined>;
  getDataSourceRecordsStore: StoreApi<DataSourceRecordStoreState>['getState'];
}) => {
  const initializer: StateCreator<GlobalStateStoreType> = (set, get) => {
    const { setSingle: setDataSourceState, remove: removeDataSourceState } = createObjectSetters<
      GlobalStateStoreType,
      'dataSources',
      QueryRequestResult
    >('dataSources', set);

    const { setSingle: setManualDataSourceState, remove: removeManualDataSourceState } =
      createObjectSetters<GlobalStateStoreType, 'manualDataSources', QueryRequestResult>(
        'manualDataSources',
        set,
      );

    const {
      setSingle: setBlockState,
      updateSingle: updateBlockState,
      setMultiple: setBlocksState,
      removeMultiple: removeBlocksState,
    } = createObjectSetters<GlobalStateStoreType, 'blocks', BlockStateUnionType>('blocks', set);

    const { setSingle: setDependencies } = createObjectSetters<
      GlobalStateStoreType,
      'dependenciesGraph',
      EntityDependency[]
    >('dependenciesGraph', set);

    const { setSingle: setPreviewTriggeredDataSource } = createObjectSetters<
      GlobalStateStoreType,
      'previewTriggeredDatasources',
      boolean
    >('previewTriggeredDatasources', set);

    const { setSingle: setPageVariableState, setMultiple: setPageVariablesState } =
      createObjectSetters<GlobalStateStoreType, 'pageVariables', PageVariableState>(
        'pageVariables',
        set,
      );

    const { setSingle: setPageFunctionState } = createObjectSetters<
      GlobalStateStoreType,
      'pageFunctions',
      PageFunctionState
    >('pageFunctions', set);

    const {
      setSingle: setManuallyTriggeredDataSource,
      removeMultiple: removeManuallyTriggeredDataSources,
    } = createObjectSetters<
      GlobalStateStoreType,
      'manuallyTriggeredDatasources',
      ManuallyTriggeredDatasourceType
    >('manuallyTriggeredDatasources', set);

    const { setMultiple: setLocation } = createObjectSetters<
      GlobalStateStoreType,
      'location',
      string
    >('location', set);

    const { setMultiple: setUserContext } = createObjectSetters<
      GlobalStateStoreType,
      'userContext',
      string
    >('userContext', set);

    const { setSingle: setDataSourceMeta } = createObjectSetters<
      GlobalStateStoreType,
      'dataSourcesMeta',
      DataSourceMetaType
    >('dataSourcesMeta', set);

    const setAggregationMetadataInDSMeta = (
      dataSourceId: string,
      data: QueryRequestResult | undefined,
    ) => {
      const currentMeta = get().dataSourcesMeta[dataSourceId];
      setDataSourceMeta(dataSourceId, { ...currentMeta, aggregationMetadata: data });
    };

    const setPreviewDataInDSMeta = (dataSourceId: string, data: QueryRequestResult | undefined) => {
      const currentMeta = get().dataSourcesMeta[dataSourceId];
      setDataSourceMeta(dataSourceId, { ...currentMeta, previewResult: data });
    };

    const { setSingle: setBlockRef, remove: removeBlockRef } = createObjectSetters<
      GlobalStateStoreType,
      'blockRefs',
      RefObject<unknown>
    >('blockRefs', set);

    const dataSourceActions = getDataSourceActions({
      get,
      removeDataSourceState,
      removeManuallyTriggeredDataSources,
      set,
      setPreviewTriggeredDataSource,
      getDataSourceRecordsStore,
      setManuallyTriggeredDataSource,
    });

    const setPreBuiltFlow: GlobalStateStoreType['actions']['setPreBuiltFlow'] = ({
      data,
      type,
    }) => {
      set((state) => {
        return {
          ...state,
          flows: {
            ...state.flows,
            [type]: data,
          },
        };
      });
    };

    const removePreBuiltFlow: GlobalStateStoreType['actions']['removePreBuiltFlow'] = (type) => {
      set((state) => {
        return {
          ...state,
          flows: {
            ...state.flows,
            [type]: undefined,
          },
        };
      });
    };

    const resetBlockRefs = () => {
      set((state) => {
        return {
          ...state,
          blockRefs: {},
        };
      });
    };

    /**
     * Generate dependency graph excluding runtime events
     *
     * The dependency graph should be created based on static block relationships,
     * not considering runtime events. Events are dynamic and occur during execution,
     * so they shouldn't influence the static dependency structure.
     *
     * Computations that happen during events are excluded from the dependency graph
     * to maintain a clear separation between static structure and runtime behavior.
     */
    const dependenciesGraph = buildDependencyGraph({
      ...(initial?.blocks ? omitKeysFromBlocks(initial.blocks, ['events']) : {}),
      ...initial?.pageFunctions,
    });

    const dataSourceDependenciesGraph = dataSourceRecords
      ? DataSourceHelper.getDataSourceIntraDependencies(dataSourceRecords)
      : {};

    const initialDataSourceDependencyFlow = getInitialDatasourceDependencyFlow(dataSourceRecords);
    const completeDatasourceDependencies = DependencyFlowHelper.getDependencyFlowStatus({
      dependencyFlowInstance: {},
      entities: initialDataSourceDependencyFlow,
      intraDependencies: dataSourceDependenciesGraph,
    });

    const setIsInitialized = () => {
      set((state) => {
        return {
          ...state,
          isInitialized: true,
        };
      });
    };

    const updateDependenciesGraph = () => {
      set((_state) => {
        return {
          ..._state,
          dependenciesGraph: buildDependencyGraph({
            // see the above comment for why we are omitting events
            ...omitKeysFromBlocks(_state.blocks, ['events']),
            ..._state.pageFunctions,
          }),
        };
      });
    };

    const reset = () => {
      set(() => storeObject);
    };

    const state: GlobalStateStore = {
      location: {},
      blocks: {},
      pageInputs: {},
      dataSources: {},
      manualDataSources: {},
      dataSourcesMeta: {},
      previewTriggeredDatasources: {},
      manuallyTriggeredDatasources: {},
      userContext: {},
      pageVariables: {},
      blockRefs: {},
      flows: {},
      deviceDetails: {
        device: DEFAULT_DEVICE_VARIANT,
      },
      permissions: {},
      dependenciesGraph,
      pageFunctions: {},
      interfaceSessionStorage: {},
      dependencyFlowManager: {
        dataSources: completeDatasourceDependencies,
      },
      intraDependencies: {
        dataSources: dataSourceDependenciesGraph,
      },
      ...initial,
    };

    const storeObject: GlobalStateStoreType = {
      ...state,
      actions: {
        removeManuallyTriggeredDataSources,
        setBlocksState,
        removeBlocksState,
        setDataSourceState,
        setBlockState,
        updateBlockState,
        setUserContext,
        setLocation,
        setManualDataSourceState,
        removeManualDataSourceState,
        datasource: dataSourceActions,
        setBlockRef,
        resetBlockRefs,
        removeBlockRef,
        setPageVariableState,
        setPageVariablesState,
        setPageFunctionState,
        setPreviewDataInDSMeta,
        setAggregationMetadataInDSMeta,
        setPreBuiltFlow,
        removePreBuiltFlow,
        deviceVariantActions: getDeviceVariantActions(set),
        updateDependenciesGraph,
        setInterfaceSessionStorage: getInterfaceSessionStorageAction(set),
        setDependencies,
        setIsInitialized,
        reset,
      },
    };

    return storeObject;
  };
  return create<GlobalStateStoreType>()(initializer);
};
