import { useMemo } from 'react';
import _pick from 'lodash/pick';
import userStore from '../../../auth/userStore';

const KEYS_TO_PICK = [
  'id',
  'email',
  'name',
  'username',
  'contactDetails',
  'customProperties',
  'roles',
];

export function useGetUserContext() {
  const data = userStore.use.currentUserDetails();
  const user = data?.user;
  const userContext = useMemo(() => (user ? { ..._pick(user, KEYS_TO_PICK) } : undefined), [user]);
  return { userContext };
}
