'use client';
import type { ContextType, PropsWithChildren } from 'react';
import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useImperativeHandle,
  useRef,
} from 'react';
import isEqual from 'react-fast-compare';
import { keyBy } from 'lodash';
import { useGetUserContext } from '../../hooks/useGetUserContext';
import { useRegistryContext } from '../../components/RegistryProvider';
import AsyncView from '../../../components/AsyncView';
import { useFetchInterfacePermissions } from '../../access-control/hooks/useFetchInterfacePermissions';
import { useRouter } from '../../hooks/useRouter';
import { useInterfaceSessionStorage } from '../../hooks/useInterfaceSessionStorage';
import { useDataSourceRecordStore } from '../DataSourceRecordStore';
import type { GlobalStateStore, GlobalStateStoreInitialValue } from './types';
import { createGlobalStateStore } from './createGlobalStateStore';
import {
  getInitialBlockState,
  getInitialPageVariablesState,
  getInitialManuallyTriggeredDataSources,
} from './utils/initial';
import { createGlobalStateStoreSelectors } from './selectors';
import type { GlobalStateStoreProviderProps } from './type';

const GlobalStateStoreContext = createContext<ReturnType<
  typeof createGlobalStateStoreSelectors
> | null>(null);

export const useGlobalStateStore = () => {
  const _useStore = useContext(GlobalStateStoreContext);
  if (!_useStore) {
    throw new Error('Missing GlobalStateStoreProvider');
  }
  return _useStore;
};

export function GlobalStateStoreProvider({
  blocks,
  pageVariables,
  pageInputs,
  deviceDetails,
  children,
  dataSources,
  mode,
  interfaceId,
  pageFunctions,
  globalStateStoreRef,
  client,
}: PropsWithChildren<GlobalStateStoreProviderProps>) {
  const getDataSourceRecordsStore = useDataSourceRecordStore().getState;
  const { registry } = useRegistryContext();
  const { pathParams } = useRouter();
  const storeRef = useRef<ContextType<typeof GlobalStateStoreContext>>();
  const { userContext } = useGetUserContext();
  const { permissions, isLoading: permissionsLoading } = useFetchInterfacePermissions({
    mode,
    interfaceId,
    client,
  });
  const { interfaceSessionStorage } = useInterfaceSessionStorage({
    interfaceId,
  });

  if (!storeRef.current && !permissionsLoading) {
    const initialValue: GlobalStateStoreInitialValue = {
      isInitialized: false,
      deviceDetails,
      pageInputs: pageInputs ?? {},
      userContext: userContext ?? {},
      manuallyTriggeredDatasources: getInitialManuallyTriggeredDataSources(dataSources),
      location: { pathParams },
      blocks: blocks ? getInitialBlockState({ blocks, registry }) : {},
      pageVariables: pageVariables ? getInitialPageVariablesState({ pageVariables }) : {},
      permissions,
      interfaceSessionStorage: interfaceSessionStorage ?? {},
      pageFunctions: pageFunctions ?? {},
    };
    storeRef.current = createGlobalStateStoreSelectors(
      createGlobalStateStore({
        dataSourceRecords: dataSources,
        initial: initialValue,
        getDataSourceRecordsStore,
      }),
    );
  }

  const isStoreInitialized = storeRef.current?.getState().isInitialized as boolean | undefined;
  const isStoreInitializing = permissionsLoading && !isStoreInitialized;

  useEffect(() => {
    if (isStoreInitialized && !isEqual(storeRef.current?.getState().pageInputs, pageInputs)) {
      const _pageInputs: GlobalStateStore['pageInputs'] = pageInputs ?? {};
      storeRef.current?.setState({ pageInputs: _pageInputs });
    }
  }, [pageInputs, isStoreInitialized]);

  useEffect(() => {
    if (
      isStoreInitialized &&
      !isEqual(storeRef.current?.getState().interfaceSessionStorage, interfaceSessionStorage)
    ) {
      const _interfaceSessionStorage: GlobalStateStore['interfaceSessionStorage'] =
        interfaceSessionStorage ?? {};
      storeRef.current?.setState({ interfaceSessionStorage: _interfaceSessionStorage });
    }
  }, [interfaceSessionStorage, isStoreInitialized]);

  useImperativeHandle(globalStateStoreRef, () => {
    return {
      updateBlocks: ({ added, changed, deleted }) => {
        const blocksToUpdate = [...added, ...changed];
        const keyByBlocks = keyBy(blocksToUpdate, 'id');
        const blockIdsToDelete = deleted.map((block) => block.id);

        const initialBlocksState = getInitialBlockState({ blocks: keyByBlocks, registry });
        storeRef.current?.getState().actions.setBlocksState(initialBlocksState);
        storeRef.current?.getState().actions.removeBlocksState(blockIdsToDelete);
        storeRef.current?.getState().actions.updateDependenciesGraph();
      },
    };
  }, [registry]);

  useEffect(() => {
    if (!isStoreInitializing) {
      storeRef.current?.getState().actions.setIsInitialized();
    }
  }, [isStoreInitializing]);

  const renderGlobalStateStore = useCallback(
    () => (
      // @ts-expect-error - storeRef is always defined when loading is false which is handled in loading check in Async view
      <GlobalStateStoreContext.Provider value={storeRef.current}>
        {children}
      </GlobalStateStoreContext.Provider>
    ),
    [children],
  );

  return (
    <AsyncView
      data={storeRef.current}
      isLoading={permissionsLoading}
      renderData={renderGlobalStateStore}
    />
  );
}
