import type { InterfaceEntity } from '@unifyapps/defs/types/interface';

export const VARIABLE_PREFIX = 'var';
export const FUNCTION_PREFIX = 'func';
export const BLOCK_PREFIX = 'b';
export const ENTITY_PREFIX = 'e'; // mongo id prefix for backend entities
export const NAV_ITEM_PREFIX = 'nav';
export const PAGE_EVENT_PREFIX = 'p_evt';
export const GLOBAL_FILTER_ITEM_PREFIX = 'gfItem';
export const PAGE_INPUT_QUERY_KEY = 'pageInputs';
export const EVENT_PREFIX = 'evt';
export const ACTION_PREFIX = 'act';
export const ROW_ACTION_PREFIX = 'rowAct';
export const BULK_ACTION_PREFIX = 'bulkAct';
export const COLUMN_ACTION_PREFIX = 'colAct';
export const REQUEST_ID_PREFIX = 'req';
export const MULTI_STAT_CARD_SECONDARY_STAT_PREFIX = 'secStat';
export const KEY_VALUE_FIELD_PREFIX = 'kvF';
export const FORM_VALIDATION_RULES_PREFIX = 'f_vRules';
export const STEPPED_CONTAINER_ITEM_PREFIX = 'stepItem';
export const COLUMN_PREFIX = 'col';
export const NAV_MENU_ITEM_PREFIX = 'navMenuItem';
export const NAVIGATION_CONTAINER_ITEM_PREFIX = 'navContainerItem';
export const COMMENT_PREFIX = 'comment';
export const REPLY_PREFIX = 'reply';
export const FIELD_ID_PREFIX = 'F';
export const MEDIA_ID_PREFIX = 'media';

export const NEW_BLOCK_ID = '__PLACEHOLDER__' as const;
export const HEADER_ID = 'header_id' as const;
export const ROOT_ID = 'root_id' as const;
export const FOOTER_ID = 'footer_id' as const;

export const INTERNALS_PARAMETERS_KEY = '__internals__';
export const CLIENT_KEY = '__ua_client__';

export const DUMMY_BLOCK_STATE = {
  id: 'dummy_block_id',
};
export const getDummyPage = (properties: Record<string, object>) => {
  return {
    id: 'page-id',
    entityType: 'e_component',
    deleted: false,
    version: 1,
    properties: {
      ...properties,
    },
  };
};
export const DUMMY_INTERFACE_RECORD: InterfaceEntity = {
  name: 'dummy-interface-name',
  entityType: 'dummy-entity-type',
  id: 'dummy-id',
  properties: {
    name: 'dummy-name',
    standard: false,
    metadata: {
      logo: {
        small: 'https://assets.unifyapps.com/theme/unifyapps/unify.svg',
        large: 'https://assets.unifyapps.com/theme/unifyapps/unify.svg',
      },
    },
    navigation: {
      defaultPageId: 'dummy-default-page-id',
      primary: [
        {
          type: 'GROUP' as const,
          id: 'dummy-nav-primary-id',
          name: 'dummy-nav-primary-name',
          icon: 'dummy-nav-primary-icon',
          items: [],
        },
      ],
    },
  },
};

export const interfacePatterns = {
  // eslint-disable-next-line optimize-regex/optimize-regex -- fine
  isBuilderMode: /.*\/interfaces\/(?!-)(?!.*--)[a-z0-9-]{1,63}(?<!-)\/builder\/.*/,
  //since this is passed as string
  subDomain: '^(?!-)(?!.*--)[a-z0-9-]+(?<!-)$',
};
